import 'vite/modulepreload-polyfill';

import '../Scss/style.scss';

import { createApp, defineAsyncComponent } from 'vue';
import mitt from 'mitt';
import VueScrollTo from 'vue-scrollto';

import PageHeader from "./components/PageHeader.vue";
import Root from './components/Root.vue';
import VideoPlayer from './directives/video-player';
import CookiesInfoBox from './plugins/cookies/InfoBox.vue';
// import VueDsCookies from './plugins/cookies';
import DetectLoading from './plugins/detect-loading';
import { i18n } from "./util";
import {ElTabPane} from 'element-plus';

const app = createApp(Root);

const eventBus = mitt();

app.provide('eventBus',eventBus);

app.component('CookiesInfoBox', CookiesInfoBox);
app.component('ajax-list', defineAsyncComponent(() => import('./components/AjaxList.vue')));
app.component('collapse', defineAsyncComponent(() => import('./components/Collapse.vue')));
app.component('custom-script', defineAsyncComponent(() => import('./components/CustomScript.vue')));
app.component('form-framework-ajax', defineAsyncComponent(() => import('@ds_form_framework/Vue/Components/FormFrameworkAjax.vue')));
app.component('form-framework-contact-handler', defineAsyncComponent(() => import('@ds_form_framework/Vue/Components/FormFrameworkContactHandler.vue')));
app.component('grid-element-modal', defineAsyncComponent(() => import('./components/GridElementModal.vue')));
app.component('news-list', defineAsyncComponent(() => import('@ds_page_types/Vue/News/List.vue')));
app.component('models-list', defineAsyncComponent(() => import('@ds_page_types/Vue/Models/List.vue')));
app.component('models-compare', defineAsyncComponent(() => import('@ds_page_types/Vue/Models/Compare.vue')));
app.component('stores-locator', defineAsyncComponent(() => import('@ds_page_types/Vue/Stores/Locator.vue')));
app.component('page-header', PageHeader);
app.component('page-hero-slider', defineAsyncComponent(() => import('./components/PageHeroSlider.vue')));
app.component('solr-search-results', defineAsyncComponent(() => import('./components/SolrSearchResults.vue')));
app.component('swiper-slide', defineAsyncComponent(() => import('swiper/vue').then(({ SwiperSlide }) => SwiperSlide)));
app.component('tab-container', defineAsyncComponent(() => import('./components/TabContainer.vue')));
app.component('el-tab-pane', ElTabPane);
app.component('text-with-image', defineAsyncComponent(() => import('./components/TextWithImage.vue')));
app.component('model-specification-slider', defineAsyncComponent(() => import('./components/ModelSpecificationSlider.vue')));
app.component('product-accessories-slider', defineAsyncComponent(() => import('./components/ProductAccessoriesSlider.vue')));
app.component('map-with-route', defineAsyncComponent(() => import('./components/MapWithRoute.vue')));
app.component('image-slider', defineAsyncComponent(() => import('./components/ImageSlider.vue')));
app.component('car-detail', defineAsyncComponent(() => import('./components/CarDetail.vue')));
app.component('car-detail-landing-page', defineAsyncComponent(() => import('./components/CarDetailLandingPage.vue')));
app.component('timeline-slider', defineAsyncComponent(() => import('./components/TimelineSlider.vue')));
app.component('interior-exterior-integration', defineAsyncComponent(() => import('./components/InteriorExteriorIntegration.vue')));
app.component('benefit-overview-slider', defineAsyncComponent(() => import('./components/BenefitOverviewSlider.vue')));

app.directive('video-player', VideoPlayer);

app.use(DetectLoading);
app.use(i18n);
app.use(VueScrollTo, {
  offset: -100,
});

app.config.compilerOptions.delimiters = [ '<%', '%>' ];
app.mount('#page');
